                /* Call to Action Button */
                
                .cta-btn {
                    display: inline-block;
                    position: relative;
                    padding: 0.8rem $default-font-size;
                    font-weight: bold;
                    line-height: 1;
                    z-index: 1;
                    transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.6s;
                    &::after {
                        content: "";
                        display: block;
                        position: absolute;
                        width: 0px;
                        height: 100%;
                        left: 0;
                        bottom: 0;
                        z-index: -1;
                        transition: all cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
                    }
                }
                /* Hero Style */
                
                .cta-btn--hero {
                    @include supportColorForIE11();
                    border-width: 2px;
                    border-style: solid;
                    -moz-border-image: -moz-linear-gradient( 135deg, var(--primary-color)0%, var(--secondary-color) 100%);
                    -webkit-border-image: -webkit-linear-gradient( 135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
                    border-image: linear-gradient( 135deg, var(--primary-color)0%, var(--secondary-color) 100%);
                    -webkit-border-image-slice: 1;
                    border-image-slice: 1;
                    @include supportIE11() {
                        color: var(--secondary-color) !important;
                        &:hover {
                            color: $white-color !important;
                        }
                    }
                    @include respond(phone) {
                        background-image: none;
                        border: 2px solid var(--secondary-color);
                        -webkit-text-fill-color: var(--secondary-color);
                    }
                    &::after {
                        background-image: linear-gradient( 135deg, var(--primary-color) 0%, var(--secondary-color) 100%);
                        @include respond(phone) {
                            background-image: none;
                        }
                    }
                    &:hover {
                        -webkit-text-fill-color: $white-color;
                        text-decoration: none;
                        @include respond(phone) {
                            -webkit-text-fill-color: var(--secondary-color);
                        }
                        &::after {
                            width: 100%;
                        }
                    }
                }
                /* gfg */
                
                .cta-btn--herogfg {
                    @include supportColorForIE11();
                    border-width: 2px;
                    border-style: solid;
                    -moz-border-image: -moz-linear-gradient( 135deg, var(--primary-color)0%, $gfg-color 100%);
                    -webkit-border-image: -webkit-linear-gradient( 135deg, var(--primary-color) 0%, $gfg-color 100%);
                    border-image: linear-gradient( 135deg, var(--primary-color)0%, $gfg-color 100%);
                    -webkit-border-image-slice: 1;
                    border-image-slice: 1;
                    @include supportIE11() {
                        color: $gfg-color !important;
                        &:hover {
                            color: $white-color !important;
                        }
                    }
                    @include respond(phone) {
                        background-image: none;
                        border: 2px solid $gfg-color;
                        -webkit-text-fill-color: $gfg-color;
                    }
                    &::after {
                        background-image: linear-gradient( 135deg, var(--primary-color) 0%, $gfg-color 100%);
                        @include respond(phone) {
                            background-image: none;
                        }
                    }
                    &:hover {
                        -webkit-text-fill-color: $white-color;
                        text-decoration: none;
                        @include respond(phone) {
                            -webkit-text-fill-color: $gfg-color;
                        }
                        &::after {
                            width: 100%;
                        }
                    }
                }
                /* leetcode */
                
                .cta-btn--heroleetcode {
                    @include supportColorForIE11();
                    border-width: 2px;
                    border-style: solid;
                    -moz-border-image: -moz-linear-gradient( 135deg, var(--primary-color)0%, $leetcode-color 100%);
                    -webkit-border-image: -webkit-linear-gradient( 135deg, var(--primary-color) 0%, $leetcode-color 100%);
                    border-image: linear-gradient( 135deg, var(--primary-color)0%, $leetcode-color 100%);
                    -webkit-border-image-slice: 1;
                    border-image-slice: 1;
                    @include supportIE11() {
                        color: $leetcode-color !important;
                        &:hover {
                            color: $white-color !important;
                        }
                    }
                    @include respond(phone) {
                        background-image: none;
                        border: 2px solid $leetcode-color;
                        -webkit-text-fill-color: $leetcode-color;
                    }
                    &::after {
                        background-image: linear-gradient(135deg, var(--primary-color) 0%, $leetcode-color 100%);
                        @include respond(phone) {
                            background-image: none;
                        }
                    }
                    &:hover {
                        -webkit-text-fill-color: $white-color;
                        text-decoration: none;
                        @include respond(phone) {
                            -webkit-text-fill-color: $leetcode-color;
                        }
                        &::after {
                            width: 100%;
                        }
                    }
                }
                /*linkedin */
                
                .cta-btn--herolinkedin {
                    @include supportColorForIE11();
                    border-width: 2px;
                    border-style: solid;
                    -moz-border-image: -moz-linear-gradient( 135deg, var(--primary-color)0%, $linkedin-color 100%);
                    -webkit-border-image: -webkit-linear-gradient( 135deg, var(--primary-color) 0%, $linkedin-color 100%);
                    border-image: linear-gradient( 135deg, var(--primary-color)0%, $linkedin-color 100%);
                    -webkit-border-image-slice: 1;
                    border-image-slice: 1;
                    @include supportIE11() {
                        color: $linkedin-color !important;
                        &:hover {
                            color: $white-color !important;
                        }
                    }
                    @include respond(phone) {
                        background-image: none;
                        border: 2px solid $linkedin-color;
                        -webkit-text-fill-color: $linkedin-color;
                    }
                    &::after {
                        background-image: linear-gradient( 135deg, var(--primary-color) 0%, $linkedin-color 100%);
                        @include respond(phone) {
                            background-image: none;
                        }
                    }
                    &:hover {
                        -webkit-text-fill-color: $white-color;
                        text-decoration: none;
                        @include respond(phone) {
                            -webkit-text-fill-color: $linkedin-color;
                        }
                        &::after {
                            width: 100%;
                        }
                    }
                }
                /* Resume Style */
                
                .cta-btn--resume {
                    color: $white-color;
                    border: 2px solid $white-color;
                    &::after {
                        background: $white-color;
                    }
                    &:hover {
                        color: var(--secondary-color);
                        text-decoration: none;
                        &::after {
                            width: 100%;
                        }
                    }
                }
                /* Arrow Button */
                
                .up i {
                    color: #272727;
                }