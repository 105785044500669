// COLORS
:root {
    --primary-color: #02aab0;
    /* Default primary color */
    --secondary-color: #00cdac;
    /* Default secondary color */
}

$white-color: #fff;
$dark-grey: #333333;
$light-grey: #d2d2d2;
$linkedin-color:#0a66c2;
$leetcode-color:#ffa116;
$github-color:#333333;
$gfg-color:#2f8d46;
$twitter-color:#1da1f2;
$dark-blue-text: #272341; // For Headings
// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;