@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");
h1 {
    font-weight: 700;
}

p,
a {
    font-family: "Montserrat", sans-serif;
    font-size: $default-font-size;
}

a,
a:link,
a:hover,
a:visited,
a:active {
    text-decoration: none;
}

a:hover {
    transition: all 0.3s ease-in-out;
}

.section-title {
    margin: 0px;
    margin-bottom: 4.5rem;
    font-size: $big-font-size;
    font-weight: bold;
    text-transform: uppercase;
    @include respond(phone) {
        font-size: 2.8rem;
    }
}

.dark-blue-text {
    color: $dark-blue-text !important;
}

.text-color-main {
    @include supportColorForIE11();
    // Responsive text purple style
    @include respond(phone) {
        background-image: none;
        -webkit-text-fill-color: var(--secondary-color);
    }
    &:hover {
        transform: translateX(2px);
    }
}